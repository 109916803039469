import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import { Section } from '../../library/page/page';
import ProductTile from '../product-tile/product-tile';

import { GET_SUGGESTIONS } from '../../shared/graphql/suggestion-queries';

import { useFitment, useMyStore } from '../../shared/hooks/hooks';

import { getVehicleInput } from '../../shared/utils/fitment';
import { isArrayWithLength } from '../../shared/utils/validators';

import './suggestion-quick-links.scss';

function SuggestionQuickLinks(props) {
  const {
    addToCartLabel = 'ADD & VIEW CART',
    className,
    page = 'DT_STORE_HOME_PAGE'
  } = props;

  const storeCode = useMyStore()?.myStore?.code;
  const { selectedFitment } = useFitment();
  const { data: suggestionsProductMap } = useQuery(GET_SUGGESTIONS, {
    skip: !storeCode,
    variables: {
      storeCode: storeCode,
      suggestionInput: {
        axleType: 'BOTH',
        isAccessorySearched: false,
        isTireSearched: false,
        isWheelSearched: false,
        latitude: 0,
        longitude: 0,
        page
      },
      vehicleInfo: getVehicleInput(selectedFitment)
    }
  });
  const products = suggestionsProductMap?.productSearchWs?.suggestion?.products;

  if (!isArrayWithLength(products)) return null;
  return (
    <Section className={className} heading="LABOR QUICK LINKS">
      <div styleName="content">
        {products.map(product => (
          <ProductTile
            addToCartLabel={addToCartLabel}
            addToCartRedirect="/cart"
            isLink={false}
            key={`recommendation-${product?.code}`}
            product={product}
            showAddToCart
            showProductImage={false}
          />
        ))}
      </div>
    </Section>
  );
}

SuggestionQuickLinks.propTypes = {
  addToCartLabel: PropTypes.string,
  className: PropTypes.string,
  page: PropTypes.string
};

export default SuggestionQuickLinks;

import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../library/link/link';
import Typography from '../../library/typography/typography';
import AddProductButton from '../../components/add-product-button/add-product-button';
import Price from '../../components/price/price';
import ProductImage from '../product-image/product-image';

import {
  getNormalizedGBB,
  getNormalizedProductType,
  getPriceInfo,
  getProductImage
} from '../../shared/utils/product';

import { LIST_IMAGE } from '../../shared/constants/image-types';
import { PRODUCT_TYPES } from '../../shared/constants/products';

import './product-tile.scss';

function ProductTile(props) {
  const {
    addToCartLabel,
    addToCartRedirect,
    className = '',
    header,
    onAddProductClick,
    onViewProductClick,
    product,
    showAddToCart,
    showProductImage = true,
    isLink = true
  } = props;
  const productType = getNormalizedProductType(product);
  const {
    brand,
    categories,
    code,
    name,
    quantity = product?.suggestedQuantity ||
      (productType === PRODUCT_TYPES.ACCESSORIES ? 1 : 4),
    rearProduct,
    url,
    unitName
  } = product || {};
  const imageUrl = getProductImage(product, LIST_IMAGE, true)?.url;
  const gbb = getNormalizedGBB(product?.gbb);
  const priceInfo = getPriceInfo(product);
  const rearPriceInfo = getPriceInfo(rearProduct);

  const { price, priceRange } = priceInfo || {};
  const { price: rearPrice, priceRange: rearPriceRange } = rearPriceInfo || {};
  const ContainerComponent = isLink ? Link : 'div';

  return (
    <div
      className={`${className} product-tile__container`}
      styleName="container"
    >
      {header && <div styleName="header">{header}</div>}
      <ContainerComponent
        styleName="link"
        {...(isLink ? { onClick: onViewProductClick, to: url } : {})}
      >
        {showProductImage && (
          <ProductImage
            accessoryType={product?.accessoryType}
            categories={categories}
            gbb={gbb}
            imageUrl={imageUrl}
            productType={productType}
            styleName="image"
          />
        )}
        <div className="product-tile__details" styleName="details">
          <div styleName="brand-name">
            <Typography size="small" styleName="brand" tag="div" weight="black">
              {productType === PRODUCT_TYPES.SERVICE ? 'Labor' : brand}
            </Typography>
            <Typography styleName="name" tag="div" weight="heavy">
              {name}
            </Typography>
            <Typography size="small" styleName="code" tag="div">
              Item #{code}
            </Typography>
          </div>

          <div
            className="product-tile__price-container"
            styleName="price-container"
          >
            <Price
              className="product-tile__price"
              isPriceForEach={!rearPrice && !priceRange}
              isPriceFromMinPrice={!rearPrice}
              price={price}
              priceRange={priceRange}
              styleName="price"
              unitName={!rearPrice ? unitName : null}
            />
            {rearPrice && (
              <>
                <span styleName="price-dash">-</span>
                <Price
                  isPriceForEach={false}
                  price={rearPrice}
                  priceRange={rearPriceRange}
                  styleName="price"
                />
              </>
            )}
          </div>

          {showAddToCart && (
            <AddProductButton
              addToCartLabel={addToCartLabel}
              addToCartRedirect={addToCartRedirect}
              isSecondary
              item={{ product, quantity }}
              onClick={onAddProductClick}
              styleName="add-to-cart"
            />
          )}
          {url && isLink && !showAddToCart && (
            <span styleName="view-details">view details</span>
          )}
        </div>
      </ContainerComponent>
    </div>
  );
}

ProductTile.propTypes = {
  addToCartLabel: PropTypes.string,
  addToCartRedirect: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.node,
  isLink: PropTypes.bool,
  onAddProductClick: PropTypes.func,
  onViewProductClick: PropTypes.func,
  product: PropTypes.object,
  showAddToCart: PropTypes.bool,
  showProductImage: PropTypes.bool
};

export default ProductTile;
